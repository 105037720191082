<template>
  <section class="advance_container">
    <article class="advance_title">{{ $t('我们的优势') }}</article>
    <section class="items">
      <AdvanceItem v-for="item in list" v-bind="item" :key="item.title" />
    </section>
  </section>
</template>

<script setup lang="ts">
import AdvanceItem from './components/advance-item.vue';
import orderIcon from '@/assets/icons/orderIcon.svg';
import processIcon from '@/assets/icons/processIcon.svg';
import lawIcon from '@/assets/icons/lawIcon.svg';
import dataIcon from '@/assets/icons/dataIcon.svg';
import itemIcon from '@/assets/icons/itemIcon.svg';
import countryIcon from '@/assets/icons/countryIcon.svg';
const list = [
  {
    title: '服务覆盖全球',
    content: '我们的办公地遍布150+国家地区，能够支持您在全球各地开展业务',
    icon: orderIcon,
  },
  {
    title: '7x24h快速响应',
    content:
      '当你遇到问题时，可以通过在线客服或微信客服和我们取得联系，我们的客户经理随时待命',
    icon: processIcon,
  },

  {
    title: '专业法律保障',
    content:
      '拥有全球法律团队，确保全球雇佣符合当地法律法规及当地员工的劳动保障',
    icon: lawIcon,
  },
  {
    title: '数据隐私合规',
    content:
      '具备ISO-27001认证，遵守欧盟GDPR和全球隐私法，确保数据安全和数据隐私',
    icon: dataIcon,
  },
  {
    title: '收费标准化',
    content:
      '我们为客户提供固定费用的报价，无任何隐藏的后期与额外收费，平台报价单真正做到让客户所见即所得',
    icon: itemIcon,
  },
  {
    title: '先进服务平台',
    content:
      '可以让企业简单高效地处理全球各类人力资源工作，可以替代企业原本庞大的人力团队，节省企业的用人与管理成本',
    icon: countryIcon,
  },
];
</script>

<style lang="less" scoped>
@media screen and(min-width:801px) {
  .advance_container {
    margin-top: 80px;
    padding: 80px 0 120px;
    background: #f5f8ff;
    .advance_title {
      font-weight: 600;
      font-size: 40px;
      line-height: 64px;
      text-align: center;
      letter-spacing: 0.5px;
      color: #202431;
    }
    .advance_title::after {
      content: "Advantage of platform";
      display: block;
      font-weight: 600;
      font-size: 26px;
      line-height: 35px;
      color: #c9cdd4;
      text-align: center;
      margin-top: 5px;
    }

    .items {
      margin: 60px auto 0;
      width: fit-content;
      display: grid;
      grid-template-columns: repeat(3, auto);
      grid-template-rows: repeat(2, 50%);
      justify-content: space-around;
      grid-row-gap: 24px;
      grid-column-gap: 24px;
    }
  }
}
</style>
<style lang="less" scoped>
@media screen and(max-width:800px) {
  .advance_container {
    margin-top: 61px;
    padding-bottom: 48px;
    background: #f5f8ff;
    .advance_title {
      padding-top: 24px;
      font-weight: 600;
      font-size: 22px;
      line-height: 36px;
      text-align: center;
      color: #202431;
    }
    .advance_title::after {
      content: "Advantage of platform";
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      color: #c9cdd4;
    }
    .items {
      margin-top: 24px;
      padding: 0 20px;
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: space-between;
      // row-gap: 16px;
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 12px;
    }
  }
}
</style>
